/* eslint-disable no-console */
import addresses from 'config/constants/contracts';
import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
}

const getTokenLogoURL = (token?: Token) => {
  try {
    if(token && token.address === addresses.buy[token.chainId]){
      return "https://www.bunnyswap.co/images/bunnylogo.png"
    }
  } catch (error) {
    console.log('getTokenLogoURL',error)
  }
  
  if (token && mapping[token.chainId]) {
    return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${token.address}/logo.png`
  }
  return null
}

export default getTokenLogoURL
